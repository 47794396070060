import React, { Component } from "react";
import { actions } from "../../store/medals";
import { connect } from "react-redux";
import styles from "./MedalsStorage.module.css";
import MedalSection from "./MedalSection";
import medal1 from "../../images/medals/1.png";
import medal2 from "../../images/medals/2.png";
import medal3 from "../../images/medals/3.png";
import medal4 from "../../images/medals/4.png";
import medal5 from "../../images/medals/5.png";
import medal6 from "../../images/medals/6.png";
import medal7 from "../../images/medals/7.png";
import medal8 from "../../images/medals/8.png";
import medal9 from "../../images/medals/9.png";

class MedalsStorage extends Component {
  render() {
    return (
      <div className={styles.medalsContainer}>
        <div className={styles.medalRow}>
          <MedalSection
            src={medal1}
            description="Крест и звезда ордена святой Анны"
            disabled={this.isDisabled(1)}
          />
          <MedalSection
            src={medal2}
            description="Крест и звезда ордена святого Владимира
I степени"
            disabled={this.isDisabled(2)}
          />
          <MedalSection
            src={medal3}
            description="Крест и звезда ордена святого Александра Невского"
            disabled={this.isDisabled(3)}
          />
          <div className={styles.medalRowBG} />
        </div>
        <div className={styles.medalRow}>
          <MedalSection
            src={medal4}
            description="Крест и звезда ордена святого Георгия
            I степени"
            disabled={this.isDisabled(4)}
          />
          <MedalSection
            src={medal5}
            description="Крест, цепь и звезда ордена святого Андрея Первозванного
            с бриллиантами "
            disabled={this.isDisabled(5)}
          />
          <MedalSection
            src={medal6}
            description="Крест и звезда ордена Марии Терезии (Австрия)"
            disabled={this.isDisabled(6)}
          />
          <div className={styles.medalRowBG} />
        </div>
        <div className={styles.medalRow}>
          <MedalSection
            src={medal7}
            description="Крест ордена святых Маврикия и Лазаря (Сардинское королевство)"
            disabled={this.isDisabled(7)}
          />
          <MedalSection
            src={medal8}
            description="Крест Красного орла (Пруссия)"
            disabled={this.isDisabled(8)}
          />
          <MedalSection
            src={medal9}
            description="Крест ордена
            Черного орла (Пруссия) "
            disabled={this.isDisabled(9)}
          />
          <div className={styles.medalRowBG} />
        </div>
      </div>
    );
  }

  isDisabled = (number) => {
    if (this.props.medals.length < number) {
      return true;
    }
  };
}

const mapStateToProps = (state) => ({
  medals: state.medals,
});

export default connect(mapStateToProps)(MedalsStorage);
