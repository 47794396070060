import React, { Component } from "react";
import map from "../../images/map.jpg";
import mapStyles from "./Map.module.css";

class Map extends Component {
  render() {
    return (
      <div className={mapStyles.mapContainer}>
        <p className={mapStyles.mapText}>Походы СУВОРОВА в 1759-1800г.г.</p>
        <div className={mapStyles.mapBGWrapper}>
          <div className={mapStyles.mapBG} />
          <div className={mapStyles.mapWrapper}>
            <img src={map} alt="Карта походов СУВОРОВА в 1759-1800г.г." />
          </div>
        </div>
      </div>
    );
  }
}

export default Map;
