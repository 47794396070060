import React, { Component } from "react";
import medalsStyles from "./Medals.module.css";
import medal1 from "../../images/medal1.svg";
import medal2 from "../../images/medal2.svg";
import medal3 from "../../images/medal3.svg";
import medal4 from "../../images/medal4.svg";

class Medals extends Component {
  medals = [medal1, medal2, medal3, medal4];

  getMedal = (number) => (
    <div
      key={number}
      className={medalsStyles["medal" + (parseInt(number) + 1)]}>
      <img src={this.medals[number]} alt={"медаль " + (parseInt(number) + 1)} />
    </div>
  );

  renderMedals = () => {
    return this.medals.map((_, i) => {
      if (i + 1 >= this.props.number) return null;
      return this.getMedal(i);
    });
  };

  render() {
    return (
      <div className={medalsStyles.medalContainer}>{this.renderMedals()}</div>
    );
  }
}

export default Medals;
