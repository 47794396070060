import React from "react";
import styles from "./AvatarSection.module.css";

const dots = (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      className={[styles.dots, styles["dots" + props.number]].join(" ")}>
      <pattern
        id="pattern-circles-blue"
        x="0"
        y="0"
        width="23"
        height="19"
        patternUnits="userSpaceOnUse">
        <circle cx="6" cy="6" r="6" className="blueDot" />
      </pattern>
      <pattern
        id="pattern-circles"
        x="0"
        y="0"
        width="23"
        height="19"
        patternUnits="userSpaceOnUse">
        <circle cx="6" cy="6" r="6" className="dot" />
      </pattern>
      <rect
        x="0"
        y="0"
        width="100%"
        height="100%"
        fill={
          props.color ? "url(#pattern-circles)" : "url(#pattern-circles-blue)"
        }
      />
    </svg>
  );
};

export default dots;
