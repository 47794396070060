import React from "react";
import Avatar from "../Avatar";
import Dots from "./Dots";
import Medals from "../Medals";
import "../layout.css";
import styles from "./AvatarSection.module.css";
import bigCloud from "../../images/cloud.png";
import smallCloud from "../../images/cloud-small.png";

const AvatarSection = (props) => {
  return (
    <div className={styles.avatarContainer}>
      <Dots color={!props.color} number={props.number} />
      <div className={styles.cloud}>
        <img src={smallCloud} alt="облако"></img>
      </div>
      <div className={styles.imgCirclWrapper}>
        <div
          className={[
            props.color && "blueBG",
            styles.circle,
            styles["circle" + props.number],
          ].join(" ")}
        />
        <Avatar color={!props.color} />
        <Medals number={props.number} />
        <div className={styles.cloud}>
          <img src={bigCloud} alt="облако"></img>
        </div>
      </div>
      <div className={styles.year}>{props.year}</div>
      <Dots color={!props.color} number={props.number} />
    </div>
  );
};

export default AvatarSection;
