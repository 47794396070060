import React from "react";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import suvorovImg from "../images/suvorov-avatar.png";
import favicon from "../images/favicon.png";
import font from "../fonts/MuseoSansCyrl900.woff";

function SEO({ lang, meta }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `,
  );

  const metaDescription = site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={site.siteMetadata.title}>
      <link rel="preload" href={font} as="font" crossorigin="anonymous" />
      <link rel="preload" href={suvorovImg} as="image" />
      <link rel="icon" href={favicon} />
      {[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: "og:title",
          content: site.siteMetadata.title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: site.siteMetadata.title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].map((curr) => (
        <meta name={curr.name || curr.property} content={curr.content} />
      ))}
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <script type="application/ld+json">
        {`{
          "@context": "https://schema.org/",
          "@type": "website",
          url: "https://suvorov290.ru",
          name: ${site.siteMetadata.title},
          description: ${site.siteMetadata.title},
        }`}
      </script>
    </Helmet>
  );
}

export default SEO;
