import React from "react";
import AvatarStyles from "./Avatar.module.css";
import suvorovImg from "../../images/suvorov-avatar.png";
import suvorovWhitePath from "../../images/white-path.svg";
import suvorovBluePath from "../../images/blue-path.svg";

const Avatar = (props) => {
  return (
    <div className={`${AvatarStyles.avatarImgWrapper} ${props.portraitStyle}`}>
      {props.color ? (
        <img src={suvorovWhitePath} alt="контур портрета" />
      ) : (
        <img src={suvorovBluePath} alt="контур портрета" />
      )}
      <img
        src={suvorovImg}
        // className={props.portraitStyle}
        alt="Портрет Суворова"></img>
    </div>
  );
};

export default Avatar;
