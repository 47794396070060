import * as React from "react";
import styles from "./styles.module.css";

import { connect } from "react-redux";
import { actions } from "../../store/medals";

class GameContainer extends React.Component {
  state = {
    outerContainerEl: null,
  };

  componentDidUpdate(prevProps) {
    if (this.props.isOpen !== prevProps.isOpen) {
      this.outerContainerEl = null;
    }
  }

  render() {
    return (
      <div style={{ padding: "20px" }}>
        <div
          ref={(node) => (this.outerContainerEl = node)}
          className={styles.runnerWrapper}
        />
        <div
          className={styles.returnButton}
          role="button"
          onClick={this.props.closeGame}>
          <p className="text">Вернуться на главную страницу</p>
        </div>
      </div>
    );
  }

  async componentDidMount() {
    const config = {
      id: "runner",
      width: this.outerContainerEl.offsetWidth,
      onWon: this.handleWon,
    };
    const { Runner } = await import("../Runner");
    this.runner = new Runner(this.outerContainerEl, config);
    this.runner.init();
  }

  handleWon = () => {
    setTimeout(() => {
      this.sendMedals();
      this.props.closeGame();
    }, 1500);
  };

  sendMedals = () => {
    const { isGameWon } = this.props;
    if (isGameWon) return;
    this.props.gameWon();
  };

  componentWillUnmount() {
    this.runner.stop();
    this.outerContainerEl = null;
  }
}

const mapStateToProps = (state) => {
  return {
    isGameWon: state.isGameWon,
  };
};

const mapActionsToProps = {
  gameWon: actions.gameWon,
};

export default connect(mapStateToProps, mapActionsToProps)(GameContainer);
