import React, { Component } from "react";
import styles from "./SendEmailModal.module.css";
import Modal from "../Modal";
import { actions } from "../../store/medals";
import { connect } from "react-redux";

class SendEmailModal extends Component {
  state = {
    isOpen: false,
    email: null,
    submitting: false,
    wasSubmit: true,
    isDisabled: true,
  };

  componentDidUpdate(prev) {
    if (
      this.props.medals.length >= 9 &&
      prev.medals.length !== this.props.medals.length
    ) {
      this.setState({ isOpen: true });
    }
  }

  toggleModal = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  handleChange = (ev) => {
    const isDisabled = !this.regEx.test(ev.currentTarget.value || "");
    this.setState({ isDisabled, email: ev.currentTarget.value });
  };

  sendForm = async () => {
    this.setState({ submitting: true });
    try {
      await window.fetch(
        `https://functions.yandexcloud.net/d4e4mci9tcc300mgh3nu/?to=${this.state.email}`,
        {
          queryStringParameters: [this.state.email],
          mode: "no-cors", // 'cors' by default,
        },
      );
    } catch (_) {
      this.setState({ error: true });
    }
    this.setState({ submitting: false, wasSubmit: true, isOpen: false });
  };

  regEx = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i);

  render() {
    return (
      <Modal isOpen={this.state.isOpen} onRequestClose={this.toggleModal}>
        <div className={styles.sendEmailContainer}>
          <div className={styles.sendEmailWrapper}>
            <h3 className={styles.headerText}>
              Введи свою почту и получи открытку с А.В.Суворовым!
            </h3>
            <br></br>
            <input
              onChange={this.handleChange}
              className={styles.emailInput}
              placeholder="example@email.com"
              type="email"></input>
            <div
              role="button"
              className={[
                styles.button,
                this.state.isDisabled && styles.disabled,
                this.state.submitting && styles.submitting,
              ].join(" ")}
              onClick={this.sendForm}>
              <p className="text">Отправить</p>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  medals: state.medals,
});

const mapActionsToProps = {
  openModal: actions.openModal,
  closeModal: actions.closeModal,
};

export default connect(mapStateToProps)(SendEmailModal);
