import React, { Component } from "react";
import textSectionStyles from "./TextSection.module.css";
import ReadMore from "./ReadMore";
import { actions } from "../../store/medals";
import { connect } from "react-redux";

class TextSection extends Component {
  render() {
    return (
      <div className={textSectionStyles.textSection}>
        <h2 className={textSectionStyles.textSectionHeader}>
          {this.props.header}
        </h2>
        <p className="text">{this.props.text}</p>
        <ReadMore
          addText={this.props.addText}
          color={this.props.color}
          number={this.props.number}
          year={this.props.year}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    medals: state.medals,
    sections: state.sections,
  };
};

const mapActionsToProps = {
  addMedals: actions.addMedals,
};

export default connect(mapStateToProps, mapActionsToProps)(TextSection);
