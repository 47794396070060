import React, { Component } from "react";
import styles from "./Quote.module.css";
import dbQuote from "../../images/quote.svg";

class Quote extends Component {
  render() {
    if (!this.props.quote) return null;
    return (
      <div className={styles.quoteWrapper}>
        <img className={styles.doubleQuotes} src={dbQuote} />
        <p className={styles.quote}>{this.props.quote}</p>
        <img className={styles.doubleQuotes} src={dbQuote} />
      </div>
    );
  }
}

export default Quote;
