import React, { Component } from "react";
import Avatar from "../Avatar";
import Dots from "../AvatarSection/Dots";
import styles from "./Start.module.css";
import mouseImg from "../../images/mouse.svg";
import bigCloud from "../../images/cloud.png";
import smallCloud from "../../images/cloud-small.png";

class Start extends Component {
  render() {
    return (
      <div className={styles.startContainer}>
        <div className={["blueBG", styles.circle].join(" ")}></div>
        <h1 className={styles.startHeader}>СУВОРОВ</h1>
        <div className={styles.imgCirclHeader}>
          <Dots />
          <div className={styles.cloud}>
            <img src={smallCloud} alt="облако поменьше" />
          </div>
          <Avatar color portraitStyle={styles.portraitImgStyles} />
          <div className={styles.cloud}>
            <img src={bigCloud} alt="большое облако" />
          </div>
          <Dots />
        </div>
        <span className={styles.startText}>
          Проект посвящен <strong>290-летию</strong> со дня рождения
          генералиссимуса <strong>220-летию</strong> его знаменитых Итальянского
          и Швейцарского походов, а также{" "}
          <strong>115-летию музея А. В. Суворова</strong>
        </span>
        <img className={styles.mouseImg} src={mouseImg} alt="Листай ниже"></img>
      </div>
    );
  }
}

export default Start;
