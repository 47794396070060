import React, { Component } from "react";
import MedalsStorage from "../MedalsStorage";
import Modal from "./Modal";
import { actions } from "../../store/medals";
import { connect } from "react-redux";

class Medals extends Component {
  render() {
    return (
      <Modal isOpen={this.props.isOpen} onRequestClose={this.props.closeModal}>
        <MedalsStorage />
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isOpen: state.openMedalsModal,
  };
};

const mapActionsToProps = {
  openModal: actions.openModal,
  closeModal: actions.closeModal,
};

export default connect(mapStateToProps, mapActionsToProps)(Medals);
