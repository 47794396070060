import React, { Component } from "react";
import gamePic from "../../images/game-picture.png";
import gameShadow from "../../images/game-shadow.svg";
import gameTitle from "../../images/game.svg";
import arrowUpWhite from "../../images/arrow-up-white.svg";
import arrowRightBlue from "../../images/arrow-right-blue.svg";
import styles from "./GameWelcome.module.css";
import Game from "../Game";
import Modal from "../Modal";

class GameWelcome extends Component {
  state = {
    isOpen: false,
  };

  toggleModal = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    return (
      <div id="game-start" className={styles.gameWelcomeContainer}>
        <div className={styles.upperArrows}>
          <div>
            <img src={arrowUpWhite} alt="стрелка вверх"></img>
          </div>
          <div>
            <img src={arrowUpWhite} alt="стрелка вверх"></img>
          </div>
        </div>
        <div className={styles.mainBlock}>
          <div onClick={this.toggleModal} className={styles.textBlock}>
            <div className={styles.textImgs}>
              <div>
                <img src={gameTitle} alt="начни игру"></img>
              </div>
              <div>
                <img src={arrowRightBlue} alt="стрелка вправо"></img>
              </div>
            </div>
            <p className={styles.gameText}>
              Кликай мышкой, чтобы преодолеть все препятствия вместе с Суворовым
              и получить награды!
            </p>
            <p className={styles.gameText}>
              Получить звание фельдмаршала в России можно было только «по
              очереди»: в русской армии в то время было 9 генералов, которые
              имели более высокий ранг. Суворов же стал исключением. Узнав о
              новом звании, он расставил по комнате 9 стульев и начал скакать
              через них, крича: «Долгорукий позади, Салтыков позади, Каменский
              позади, мы впереди!».
            </p>
          </div>
          <div className={styles.imgBlock}>
            <img
              className={styles.soldersImg}
              src={gamePic}
              alt="солдаты суворова"></img>
            <img
              className={styles.gameShadow}
              src={gameShadow}
              alt="тень"></img>
          </div>
        </div>
        <Modal isOpen={this.state.isOpen} onRequestClose={this.toggleModal}>
          <Game isOpen={this.state.isOpen} closeGame={this.toggleModal} />
        </Modal>
      </div>
    );
  }
}

export default GameWelcome;
