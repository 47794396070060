import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Start from "../components/Start";
import Section from "../components/Section";
import Footer from "../components/Footer";
import Map from "../components/Map";
import GameWelcome from "../components/GameWelcome";
import ModalMedals from "../components/Modal/medals";
import SendEmailModal from "../components/SendEmailModal";

import texts from "../texts/texts.json";

class IndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title="Home" />
        <Start />
        <ModalMedals />
        <SendEmailModal />
        <Section
          number={1}
          color
          year="1729"
          header="Рождение воина"
          text="Не известно, когда и где родился Александр Суворов. Сам он говорил, что в 1730 году, но вот документы, по которым можно было бы установить возраст полководца, указывают на 1729 год. В автобиографии Суворов упомянул, что в 1742 году, в 15 лет, поступил на службу, значит год его рождения и вовсе — 1727. Назван мальчик был в честь Александра Невского. В 1742 году Суворова зачислили в Семеновский лейб-гвардии полк мушкетером. Правда тогда ему было только 12 лет, и до совершеннолетия он продолжал обучение военным наукам дома. А на действительную службу поступил в 18 лет."
          addText={texts["1"]}
          quote="Ученье свет, а неученье — тьма"
        />
        <Section
          number={2}
          year="1760"
          header="Военная карьера при Екатерине II"
          text="Настоящая служба настигла Суворова во время Семилетней войны. Первые годы войны он был в тылу и заведовал снабжением действующей армии провизией. Затем Суворов отправился на фронт, и уже через год одержал свою первую победу, обратив в бегство немецких драгун. В 1760 году русская армия берет Берлин, а через несколько месяцев Суворов наносит множество поражений прусским отрядам. После Семилетней войны Суворов был произведен в чин полковника и назначен командиром Астраханского пехотного полка. В Москве Суворов встречается с только что вступившей на престол императрицей Екатериной II, которая дарит ему свой портрет. На нем он позже напишет: “Это первое свидание проложило мне путь к славе…”."
          addText={texts["2"]}
        />
        <Map />
        <Section
          number={3}
          color
          year="1768"
          header="Русско-турецкие войны"
          text={
            <span>
              Вот кто по-настоящему боялся Суворова, так это турки. Он выбил
              себе назначение на балканский театр театр русско-турецкой войны
              1768-74 годов в армию фельдмаршала Петра Румянцева. И буквально
              через две недели после приезда, Суворов получает приказ атаковать
              укрепленный турецкий лагерь у Туртукая. 800 русских солдат
              разгромили 4 тысячи турок. Вскоре Суворову снова пришлось
              захватывать Туркай, за что он был награжден орденом святого
              Георгия 2-ой степени. К концу кампании Суворов не только победил в
              нескольких крупных сражениях, но и буквально принес России победу
              в битве при Козлуджи, переименованном затем в Суворово. Эта победа
              открыла России путь к миру. По результатам мирного договора
              Российская империя получила право иметь свой флот на Чёрном море и
              беспрепятственно проходить через проливы Босфор и Дарданеллы.
              <br />
              <br />
              Суворов не раз радовал императрицу Екатерину II. По-настоящему она
              оценила его заслуги после того, как Суворов подавил в Польше
              Восстание Костюшко. Войска Суворова штурмовали предместье польской
              столицы - Прагу и заняли ее. В центре Варшавы магистрат поднес
              Суворову хлеб-соль и городские ключи. Об этом Суворов написал
              императрице: «Ура! Варшава наша!» и получил ответ «Ура!
              Фельдмаршал Суворов!».
            </span>
          }
          addText={texts["3"]}
          quote="Дисциплина — мать победы"
        />
        <GameWelcome />
        <Section
          number={4}
          year="1799"
          header="Суворов и Павел I"
          text="Правда наследник престола Павел I с фельдмаршалом Суворовым общем язык не нашел. Поборник всего прусского Павел ввел в армии новые порядки, форму и устав. Суворов же продолжал воспитывать солдат собственными, проверенными в бою методами. «Русские прусских всегда бивали, что ж тут перенять?» Разозлившийся император сослал Суворова в отставку, не разрешив ему даже носить мундир. По доносу Михаила Румянцева близких к Суворову офицеров арестовали, правда позже выпустили. А самого полководца буквально заперли в собственном имении в Кочанском."
          addText={texts["4"]}
        />
        <Section
          number={5}
          color
          year="1800"
          header="Итальянский и швейцарский поход"
          text="Прибыв в Вену Суворов получает звание генерал-фельдмаршала Священной Римской империи и вскоре отправляется к войскам. В первом же крупном сражении на реке Адде им были разбиты французы. Через три дня Суворов вошел в Милан. Под натиском союзных войск французы отступают. Почти весь север Италии свободен от французских войск. В июне 1799 года русско-австрийские войска Суворова встретили врага на реке Треббия и за трое суток разгромили Наполеона. В июле под напором русский войск и их союзников пали крепости Александрия и Мантуя. За это Суворов был награжден Сардинским королем титулом «Кузен короля» и грандом Сардинского королевства и получил чин Великого маршала войск пьемонтских. Павел I тоже не остался в долгу и принял Суворова в царскую фамилию. Итальянский поход Суворова заканчивается в августе 1799 года 18 часовым сражением при Нови, где союзническая армия полностью разгромила французов и освободила Северную Италию."
          addText={texts["5"]}
        />
        <Footer></Footer>
      </Layout>
    );
  }
}

export default IndexPage;
