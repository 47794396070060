import React, { Component } from "react";
import years from "../../images/290-years.svg";
import footerPic from "../../images/footer-picture-crop.png";
import FooterStyles from "./Footer.module.css";

class Footer extends Component {
  render() {
    return (
      <footer className={FooterStyles.footerWrapper}>
        <div className={FooterStyles.footerPic}>
          <img src={footerPic} alt="Суворов перед солдатами"></img>
        </div>
        <div className={FooterStyles.yearsWrapper}>
          <img src={years} alt="290 лет"></img>
          <div className={FooterStyles.button}>
            <a href="http://suvorovmuseum.ru/" target="blank">
              <p className={FooterStyles.titleSuvorov}>
                ПОСЕТИТЬ МУЗЕЙ А.В. СУВОРОВА
              </p>
            </a>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
