import React, { Component } from "react";
import textSectionStyles from "./TextSection.module.css";
import starPath from "../../images/star.svg";
import expandIcon from "../../images/down-icon.svg";
import { Link } from "gatsby-plugin-modal-routing";
import { actions } from "../../store/medals";
import { connect } from "react-redux";

class TextSection extends Component {
  handleClick = () => {
    const { sections, number } = this.props;

    if (sections.includes(number)) return;

    this.props.addMedals({ section: number });
  };

  openStarModal = () => this.props.openModal();

  render() {
    const { sections, number } = this.props;
    if (!this.props.addText) return null;
    return (
      <div className={textSectionStyles.readMoreWrapper}>
        <Link
          to={`/text/${this.props.year}`}
          asModal
          state={{
            addText: this.props.addText,
            noScroll: true,
            to: this.props.year,
          }}>
          <div
            role="button"
            className={[
              textSectionStyles.readMoreButton,
              this.props.color && "blueBG",
            ].join(" ")}
            onClick={this.handleClick}>
            <p className={textSectionStyles.readMoreButtonText}>Читать далее</p>
            <img src={expandIcon} alt="стрелка вниз"></img>
          </div>
        </Link>
        <div
          className={[
            textSectionStyles.starWrapper,
            sections.includes(number) && "blueBG",
          ].join(" ")}
          onClick={this.openStarModal}>
          <img
            src={starPath}
            alt="Нажмите 'читать далее', что бы получить медаль"></img>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    medals: state.medals,
    sections: state.sections,
  };
};

const mapActionsToProps = {
  addMedals: actions.addMedals,
  openModal: actions.openModal,
};

export default connect(mapStateToProps, mapActionsToProps)(TextSection);
