import React, { Component } from "react";
import ReactModal from "react-modal";
import styles from "./Modal.module.css";

ReactModal.setAppElement("#___gatsby");

class Modal extends Component {
  render() {
    return (
      <ReactModal
        isOpen={this.props.isOpen}
        onRequestClose={this.props.onRequestClose}
        overlayClassName={styles.overlayClassNameModal}
        className={styles.modalContentClassName}
        contentLabel="Модальное окно">
        {this.props.children}
      </ReactModal>
    );
  }
}

export default Modal;
