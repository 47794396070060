import React from "react";
import SectionStyles from "./Section.module.css";
import AvatarSection from "../AvatarSection";
import TextSection from "../TextSection";
import Quote from "../Quote";

const Section = (props) => {
  return (
    <section
      id={props.year}
      className={[
        SectionStyles.sectionBG,
        props.color && SectionStyles.sectionBGColor,
      ].join(" ")}>
      <div className={SectionStyles.sectionWrapper}>
        <div style={{ position: "relative" }}>
          <AvatarSection
            color={!props.color}
            year={props.year}
            number={props.number}
          />
          <Quote quote={props.quote}></Quote>
        </div>
        <TextSection
          number={props.number}
          header={props.header}
          text={props.text}
          addText={props.addText}
          year={props.year}
          color={!props.color}
        />
      </div>
    </section>
  );
};

export default Section;
